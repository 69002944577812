<template>
	<div class="report-layout" align="center">
		<div class="page">
			<HeaderReport :RadioName="parentName ? parentName : nameRadio" :RadioLogo="logoRadio" />
			<div class="md-layout" style="padding: 0 0 0 0;">
				<div class="md-layout-item md-size-90" style="margin: 1rem auto;">
					<div style="display: flex; justify-content: space-between; align-items: center;">
						<div>
							<h1 style="margin: 0; text-align:left; font-weight: bold; width: 90%;">
								Relatório de ouvintes de notificações
							</h1>
						</div>
						<div>
							<h5 style="margin: 0; text-align:right; font-weight: bold;">
								Período de apuração
							</h5>
							<h3 style="margin: 0; text-align:right; font-weight: bold;">
								{{ periodStart }} a {{ periodEnd }}
							</h3>
							<h3 style="margin: 0; text-align:right; font-weight: bold;">
								Tópico: {{ topic }}
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div class="md-layout md-alignment-center-center" style="height: 60%;">
				<div class="md-layout-item md-size-90">
					<md-card>
						<md-card-header>
							<h3 style="margin: 10px 0 0 5px !important; text-align: left;">
								Gráfico de ouvintes por dia
							</h3>
						</md-card-header>
						<md-card-content class="md-content-dashboard">
							<div class="md-layout">
								<div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 row-icon-dashboard platform-data-container"
									style="padding: 0;"
									id="chart-listeners-per-platform-template">
									
								</div>
							</div>
						</md-card-content>
					</md-card>
				</div>
			</div>
		</div>

		<div class="page" v-for="groupOfDates, index in this.getDatesInGroupsOf40()">
			<header class="second-page-header">
				<h3>Relatório de ouvintes de notificações</h3>
				<h3>{{ periodStart }} a {{ periodEnd }}</h3>
			</header>
			<div class="table-container md-layout-item md-size-90" style="margin: auto;">
				<h4 class="md-layout-item md-size-95" style="margin: 1rem;">Ouvintes por dia</h4>
				<table class="md-layout-item md-size-50" style="display: inline-block;">
					<thead>
						<th>Dia</th>
						<th>Android</th>
						<th>iOS</th>
						<th>Total</th>
					</thead>
					<tr class="tr_tables" v-for="(item, index) in groupOfDates.slice(0, Math.ceil(groupOfDates.length / 2))"
						:key="index">
						<td class="td_tables">{{ (new Date(item.date)).toLocaleDateString("pt-BR") }}</td>
						<td class="td_tables">{{ item.android | formatNumber }}</td>
						<td class="td_tables">{{ item.ios | formatNumber }}</td>
						<td class="td_tables">{{ item.total | formatNumber }}</td>
					</tr>
				</table>
				<table class="md-layout-item md-size-50" style="display: inline-block;">
					<thead>
						<th>Dia</th>
						<th>Android</th>
						<th>iOS</th>
						<th>Total</th>
					</thead>
					<tr class="tr_tables"
						v-for="(item, index) in groupOfDates.slice(Math.floor(groupOfDates.length / 2), groupOfDates.length)"
						:key="index">
						<td class="td_tables">{{ (new Date(item.date)).toLocaleDateString("pt-BR") }}</td>
						<td class="td_tables">{{ item.android | formatNumber }}</td>
						<td class="td_tables">{{ item.ios | formatNumber }}</td>
						<td class="td_tables">{{ item.total | formatNumber }}</td>
					</tr>
				</table>
			</div>
			<FooterReport />
		</div>
		<div class="page" v-if="radiosConsolidated.length > 1">
			<header class="second-page-header">
				<h3>Relatório de sessões</h3>
				<h3>{{ periodStart }} a {{ periodEnd }}</h3>
			</header>
			<div class="table-container md-layout-item md-size-90" style="margin: auto;">
				<h4 class="md-layout-item md-size-95" style="margin: 1rem;">Rádios consolidadas</h4>
				<div class="md-layout md-alignment-top-center">
					<table v-for="radioGroup in this.getRadiosInGroupsOf20()" class="md-layout-item md-size-30"
						style="display: inline-block;">
						<tr class="tr_tables" v-for="(item, index) in radioGroup" :key="index">
							<td class="td_tables">{{ item.name }}</td>
						</tr>
					</table>
				</div>
			</div>
			<FooterReport />
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import HeaderReport from "./partials/HeaderReport.vue";
import FooterReport from "./partials/FooterReport.vue";

import numeral from "numeral";

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

numeral.locale("pt-br");

Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	name: "TemplateReportAudience",
	components: {
		HeaderReport,
		FooterReport
	},
	props: [
		"parentName",
		"nameRadio",
		"logoRadio",
		"topic",
		"radiosConsolidated",
		"periodStart",
		"periodEnd",
		"data"
	],
	methods: {

		buildChartListenersPerDay() {
			Highcharts.chart('chart-listeners-per-platform-template', {
                chart: {
                    type: 'spline',
					width: 650,
                    height: 500
                },
				plotOptions: {
					series: {
						animation: false // Desabilitar animações das séries
					}
				},
                exporting: {
                    enabled: false // Desabilitar a exportação de botões
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: this.$props.data.tablePushNotification.map(e => (new Date(e.date)).toLocaleDateString("pt-BR")),
                    title: {
                        text: "Data"
                    },
                    labels: {
                        step: 2,
                        rotation: -40,
                        padding: 20
                    }
                },
                yAxis: [{
                    title: {
                        text: 'Ouvintes de push'
                    }
                }],
                series: [{
                    name: 'Android',
                    data: this.$props.data.tablePushNotification.map(e => e.android),
                    color: "#5BB643"
                },
                {
                    name: 'iOS',
                    data: this.$props.data.tablePushNotification.map(e => e.ios),
                    color: "#000000"
                }
                    , {
                    name: 'Total',
                    data: this.$props.data.tablePushNotification.map(e => e.total),
                    color: "#D30000",
                    dashStyle: 'LongDash'
                }]
            });
		},
		getDatesInGroupsOf40() {
			let dates = this.$props.data.tablePushNotification
			let groupsOfDates = []
			for (let i = 0; i < Math.ceil(dates.length / 40); i++) {
				let group = dates.slice(i * 40, (i + 1) * 40)
				groupsOfDates.push(group);

			}
			return groupsOfDates
		},
		getRadiosInGroupsOf20() {
			let radios = this.$props.radiosConsolidated
			let groupsOfRadios = []
			for (let i = 0; i < Math.ceil(radios.length / 20); i++) {
				let group = radios.slice(i * 20, (i + 1) * 20)
				groupsOfRadios.push(group);

			}
			return groupsOfRadios
		}
	},
	watch: {
		data: function () {
			this.buildChartListenersPerDay()
		},
		deep: true,
		immediate: true
	}
};
</script>

<style scoped>
@page {
	size: auto;
	margin: 0 0 0 0;
}

@media print {
	body {
		overflow-y: hidden;
	}

	.page {
		width: 210mm;
		height: 296mm;
		text-align: center;
	}
}

#chart-listeners-push-template {
	box-shadow: none;
	border: none !important;
	width: 100%;
}

#chart-listeners-per-platform-template {
	height: 350px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: none !important;
}

ul {
	text-decoration: none;
}

ul li {
	display: inline-block;
	font-size: 12pt;
	background-color: #485ef3;
	color: #ffffff !important;
	height: 20px;
	padding: 3px 8px;
	margin: 3px;
	border-radius: 10px;
}

#chartDivOsReport,
#chartDivVersionReport {
	width: 50mm;
	height: 100mm;
	margin: 0 auto auto auto;
}

.report-layout td {
	text-align: center;
}

.report-layout {
	print-color-adjust: exact !important;
	-webkit-print-color-adjust: exact !important;
	background-color: #f4f4f4;
}

.page {
	position: relative;
}

.report-layout * {
	color: #707070;
	font-family: "Poppins" !important;
}

.second-page-header {
	width: 100%;
	height: 5rem;
	padding-top: 0.5rem;
	margin-bottom: 2rem;
	background: linear-gradient(90deg, #485ef3, #49b6ea);
}

.second-page-header h3 {
	color: #fff;
	font-weight: bold;
	margin: 0;
}

.table-container {
	margin: auto;
	background: #fff;
	width: 90%;
	padding: 1rem;
	border-radius: 3rem;
	border: 3px solid #485ef3;
}

.table-container h4 {
	font-weight: bold;
	font-size: 22px;
	grid-column: span 2;
}

.table-container * {
	color: #485ef3;
}

.table-container img {
	width: 16px;
}

.table-container table {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1rem;
}

.table-container td {
	border-bottom: 2px solid #485ef3;
	text-align: center;
	font-size: 9px;
	padding: 10px 0;
}

.first-col {
	font-size: 13px;
	font-weight: bold;
}</style>
