<template>
	<div>
		<div class="md-layout">
			<div
				v-if="loading"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<Spinner />
			</div>
			<div v-else class="md-layout-item md-size-100">
				<FilterSelector
					@searchData="getPushNotificationReport()"
					:disableSearch="!this.selectedTopic"
					:disablePrint="this.tablePushNotification.length === 0"
					:filterForReport="true"
					:buttonsToDisplay="[
						buttonEnum.YESTERDAY,
						buttonEnum.SEVEN_DAYS,
						buttonEnum.MONTH_TO_DATE,
						buttonEnum.CUSTOM,
					]"
				>
					<template #filters>
						<div class="md-layout">
							<h3
								class="md-layout-item md-size-100"
								style="
									font-size: 1rem;
									text-align: left;
									margin: 25px 0 10px 0;
									padding: 0;
									margin-top: 25px;
									font-size: 1rem;
									font-weight: 100;
								"
							>
								Tópico
							</h3>
							<el-select
								v-model="selectedTopic"
								placeholder="Tópico de notificação"
							>
								<el-option
									v-for="(topic, index) in listOfTopics"
									:key="index"
									:value="topic"
								>
									{{ topic }}
								</el-option>
							</el-select>
						</div>
					</template>
					<template #other-options>
						<md-button
							@click="printReport()"
							:disabled="tablePushNotification.length === 0"
							class="md-info"
						>
							Imprimir
						</md-button>
					</template>
				</FilterSelector>
			</div>
			<div
				v-show="this.tablePushNotification.length > 0"
				class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 principal"
			>
				<md-card>
					<md-card-header>
						<h3 style="margin: 10px 0 0 5px !important; text-align: center">
							GRÁFICO DE OUVINTES POR DIA
						</h3>
					</md-card-header>
					<md-card-content
						class="md-content-dashboard"
						v-show="this.tablePushNotification.length > 0"
					>
						<div class="md-layout">
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 row-icon-dashboard platform-data-container"
								style="padding: 0"
								id="chart-listeners-per-platform"
							></div>
						</div>
					</md-card-content>
				</md-card>
			</div>
			<div
				v-show="this.tablePushNotification.length > 0"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 principal"
				style="margin: auto"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<h3>OUVINTES POR DIA</h3>
					</md-card-header>

					<md-card-content>
						<table class="table_main_1">
							<thead style="text-align: left">
								<th>DATA</th>
								<th>ANDROID</th>
								<th>IOS</th>
								<th>TOTAL</th>
							</thead>
							<tbody>
								<tr
									class="tr_tables"
									v-for="(item, index) in tablePushNotification"
									:key="index"
								>
									<td class="td_tables">
										{{ new Date(item.date).toLocaleDateString("pt-BR") }}
									</td>
									<td class="td_tables">{{ item.android | formatNumber }}</td>
									<td class="td_tables">{{ item.ios | formatNumber }}</td>
									<td class="td_tables">{{ item.total | formatNumber }}</td>
								</tr>
							</tbody>
						</table>
					</md-card-content>
				</md-card>
			</div>
			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card style="text-align: center" v-show="this.showMsg">
					<md-card-content>
						<label for="">Sem informações para serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
			<TemplateReportPushNotification
				id="report"
				:nameRadio="this.radio.name"
				:logoRadio="this.radio.logo"
				:periodStart="this.periodStart"
				:periodEnd="this.periodEnd"
				:topic="this.selectedTopic"
				:parentName="this.radio.parent_name"
				:radiosConsolidated="[]"
				:data="{
					tablePushNotification: this.tablePushNotification,
				}"
			/>
		</div>
	</div>
</template>

<script>
import Spinner from "../../../components/Icons/Spinner";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import { DatePicker } from "element-ui";
import jwt_decode from "jwt-decode";
import models from "../../../routes/models.js";
import numeral from "numeral";
import Swal from "sweetalert2";
import Vue from "vue";

import buttonEnum from "../Components/FilterSelector/buttonEnum";

import TemplateReportPushNotification from "./templates/TemplateReportPushNotificationListeners.vue";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		[DatePicker.name]: DatePicker,
		Spinner,
		TemplateReportPushNotification,
		FilterSelector,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			loading: false,
			tablePushNotification: [],
			listOfTopics: [],
			selectedTopic: null,
			periodStart: null,
			periodEnd: null,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			showMsg: false,
		};
	},
	methods: {
		getPushNotificationTopics() {
			models()
				.get(`report/getPushNotificationTopicsForReport?app_key=${this.appKey}`)
				.then((res) => {
					this.listOfTopics = res.data.map((e) => e.topic);
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					Swal(
						"Ops!",
						"Houve algum erro ao recuperar os tópicos de notificação. Caso o erro persistir, entre em contato com o suporte",
						"error"
					);
				});
		},
		getPushNotificationReport() {
			const requestQuery = `app_key=${this.appKey}&topic=${
				this.selectedTopic
			}&start_date=${this.formatDateToSQL(filters.startDate)} 00:00:00
				&end_date=${this.formatDateToSQL(filters.endDate)} 23:59:59`;
			this.loading = true;
			this.showMsg = false;
			this.tablePushNotification = [];
			models()
				.get(`report/getPushNotificationListenersReport?${requestQuery}`)
				.then((res) => {
					this.tablePushNotification = res.data;
					this.periodStart = new Date(filters.startDate).toLocaleDateString(
						"pt-BR"
					);
					this.periodEnd = new Date(filters.endDate).toLocaleDateString(
						"pt-BR"
					);
					this.loading = false;
					if (this.tablePushNotification.length === 0) {
						this.showMsg = true;
					} else {
						this.buildChartListenersPerDay();
					}
				})
				.catch((err) => {
					console.log(err);
					Swal(
						"Ops!",
						"Houve algum erro ao recuperar os dados do relatório de push notification. Caso o erro persistir, entre em contato com o suporte",
						"error"
					);
				});
		},
		buildChartListenersPerDay() {
			Highcharts.chart("chart-listeners-per-platform", {
				chart: {
					type: "spline",
				},

				exporting: {
					enabled: false, // Desabilitar a exportação de botões
				},
				title: {
					text: "",
				},
				xAxis: {
					categories: this.tablePushNotification.map((e) =>
						new Date(e.date).toLocaleDateString("pt-BR")
					),
					title: {
						text: "Data",
					},
					labels: {
						rotation: -40,
						padding: 20,
					},
				},
				yAxis: [
					{
						title: {
							text: "Ouvintes de push",
						},
					},
				],
				series: [
					{
						name: "Android",
						data: this.tablePushNotification.map((e) => e.android),
						color: "#5BB643",
					},
					{
						name: "iOS",
						data: this.tablePushNotification.map((e) => e.ios),
						color: "#000000",
					},
					{
						name: "Total",
						data: this.tablePushNotification.map((e) => e.total),
						color: "#D30000",
						dashStyle: "LongDash",
					},
				],
			});
		},
		formatDateToSQL(date) {
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		},
		printReport() {
			window.print();
		},
	},

	computed: {
		pickerOptionsStart() {
			return {
				disabledDate: (time) => {
					if (!this.endDate) {
						return false;
					}
					const maxDate = this.endDate ? new Date(this.endDate) : new Date();
					maxDate.setDate(maxDate.getDate() - 6); // Data máxima permitida (7 dias atrás)

					return time.getTime() > maxDate;
				},
			};
		},
		pickerOptionsEnd() {
			return {
				disabledDate: (time) => {
					const minDate = this.startDate
						? new Date(this.startDate)
						: new Date();
					minDate.setDate(minDate.getDate() + 7);

					const maxDate = new Date(this.startDate);
					maxDate.setDate(maxDate.getDate() + 30);

					return time.getTime() < minDate || time.getTime() > maxDate;
				},
			};
		},
	},
	mounted() {
		this.getPushNotificationTopics();
	},
	watch: {
		endDate() {
			this.disabledButton = !(this.startDate !== null && this.endDate !== null);
		},
		startDate() {
			this.disabledButton = !(this.startDate !== null && this.endDate !== null);
		},
		tablePushNotification() {
			this.btnImprimir = this.tablePushNotification.length <= 0;
		},
	},
};
</script>
<style>
#chartDivOsPush {
	width: 100%;
	height: 300px;
}

#chart-listeners-push {
	box-shadow: none;
	width: 80%;
}

#chart-listeners-per-platform {
	height: 500px;
}

#report {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

@media print {
	#report {
		position: static;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		visibility: visible;
	}

	.principal {
		display: none;
	}
}
</style>
